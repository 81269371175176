<template>
    <bs-popup id="master-customer-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
        <template v-slot:title>Fee Profile</template>
        <template v-slot:content>
            <bs-error-message :errors="errorMessages" />
            <div class="mb-3">
                <label for="name" class="form-label fw-bold">Name</label>
                <bs-plain-text v-model="model.nama" />
            </div>

            <div class="mb-3">
                <label for="timeRangeNama" class="form-label fw-bold">Time Range</label>
                <bs-plain-text v-model="model.timeRangeNama" />
            </div>

            <div class="mb-3">
                <label for="startDay" class="form-label fw-bold">Start Day</label>
                <div>
                    {{ formatStartDay(model.startDay) }}
                </div>
            </div>

            <div class="mb-3">
                <label for="locationCode" class="form-label fw-bold">Location</label>
                <bs-plain-text v-model="model.locationCode" />
            </div>

            <div class="mb-3">
                <label for="currencyCode" class="form-label fw-bold">Currency</label>
                <bs-plain-text v-model="model.currencyCode" />
            </div>

            <div class="mb-3">
                <label for="unitCode" class="form-label fw-bold">Unit</label>
                <bs-plain-text v-model="model.unitCode" />
            </div>

            <div class="mb-3">
                <label for="airlineName" class="form-label fw-bold">Airline</label>
                <bs-plain-text v-model="model.airlineName" />
            </div>

            <div class="mb-3">
                <label for="vendorName" class="form-label fw-bold">Supplier</label>
                <bs-plain-text v-model="model.vendorName" />
            </div>

            <div class="mb-3">
                <label for="feeProfileList" class="form-label fw-bold">Fee List</label>
                <div :key="'list-' + index" v-for="(item, index) in model.feeProfileList">
                    - {{ formatDummyFee(item.feeId) }}
                </div>
            </div>

        </template>
        <template v-slot:footer>
            <!-- <button class="btn btn-sm btn-primary" @click="editData"><i class="bi bi-pencil-square"></i> Edit</button> -->
            <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
        </template>
    </bs-popup>
</template>

<script>
import FeeProfileService from "@/services/fee-profile-service.js";
import {OPSI_START_DAY} from "@/plugins/constant";

export default {
    data: function() {
        return {
            id: null,
            model: FeeProfileService.default(),
            errorMessages: null
        }
    },
    mounted() {
        let id = this.$route.params.id;
        this.id = id;
        this.loadData();
    },
    methods: {
        async loadData() {
            let response = await FeeProfileService.getObject(this.id);
            this.model = response.data;
            if (response.status) 
            {
                this.errorMessages = null;
            }
            else 
            {
                this.errorMessages = response.errorMessages;
            }
        },
        onPopupClosed() {
            this.$router.push('/fee-profile');
        },
        close() {
            this.$refs.form.closePopup();
        },
        // editData() 
        // {
        //     this.$refs.form.closePopup();
        //     this.$router.push(`/fee-profile/edit/${this.id}`);
        // }
        formatStartDay(day){
            if(day == 0) day = 7;
            return OPSI_START_DAY.filter((item)=>item.value === day)[0].label;
        },
        formatDummyFee(id){
            var feeList = [
				{
					id: 1,
					name: "Government Fee",
				},
				{
					id: 2,
					name: "Throughput Fee",
				},
				{
					id: 3,
					name: "Flowage Fee",
				},
			];
            return feeList[id-1]?.name;
        }
    }
}
</script>